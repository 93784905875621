import React, {useParams, useCallback, useState, useEffect } from 'react';
import Multiselect from "react-widgets/Multiselect";
import env from "react-dotenv";
import { basicAuth } from '../api/authapi';
import { useAuth } from '../context/AuthContext';

const BE = env.API_ENDPOINT;

function MultiselectWithCreate(props) {

  const [models, setModels] = useState([]);
  const [value, setValue] = useState([props.values]); 

  const axios = require('axios');

  const Auth = useAuth();

  useEffect(() => {
    setValue(props.values);
  }, [props.values])

  useEffect(() => {
    fetchModels().catch((e) => {console.log(e); console.log("an error occurred")});

  }, []);

  const setModelsForParent = (models) => {
    props.setModelsData(models);
  }

  const fetchModels = async () => {
    const data = await fetch (
      BE + '/api/model/all/paged?size=100000000&page=0',
    );

    const items = await data.json();
    setModels(m => items.content);
    console.log("fetched models");
    console.log(models);
  }

  let onCreate = false;
  async function handleCreate(name) {
    console.log("handleCreate");
    // select new option
    const newItem = {name: name, id: ''};
    console.log("BEFORE");
    console.log(value);
    if (value != undefined) {
      setValue([newItem, ...value]);
    }
    else { 
      setValue([newItem]);
    }
    console.log("AFTER");
    console.log(value);
    // add new option to our dataset
    setModels(data => [newItem, ...data]);
    props.setModelsData(value);
    // axios insert into db the new model
    console.log(props);
  }

  function onChangeHandler(values) {
    console.log("onChangeHandler");
    console.log(values);
    console.log(props);
    setValue(values);
    props.setModelsData(values);
  }

  return (
    <Multiselect
      data={models}
      allowCreate="onFilter"
      value={value}
      valueFiled = {"id"}
      textField = {"name"}
      onCreate={handleCreate}
      onChange={onChangeHandler}
    />
  )
}

export default MultiselectWithCreate;
