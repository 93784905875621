import React, {useCallback, useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDropzone} from 'react-dropzone'
import "react-widgets/styles.css";
import NumberPicker from "react-widgets/NumberPicker";
import DropdownList from "react-widgets/DropdownList";
import {Checkbox} from 'react-widget-checkbox';
import 'react-widget-checkbox/style';
import MultiselectWithCreate from '../components/modelMultiSelectWithCreate';
import env from "react-dotenv";
import LoadingSpinner from "../components/loadingspinner";

import { basicAuth } from '../api/authapi';
import { useAuth } from '../context/AuthContext';

const axios = require('axios');

const listStyle = {"listStyleType" :"none"};
const dropdownStyle = {"witdh": "100%"};

const BE = env.API_ENDPOINT;
const STORE = env.STORE_ENDPOINT;

function UploadProduct() {
    const navigate = useNavigate();
    const [file, setFile] = useState({});
    const [preview, setPreview] = useState(null);
    const [price, setPrice] = useState(0);
    const [inventory, setInventory] = useState(0);
    const [condition, setCondition] = useState('functional');
    const [status, setStatus] = useState('second-hand');
    const [tested, setTested] = useState(true);
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [category, setCategory] = useState({});
    const [models, setModels] = useState([]);
    const Auth = useAuth();

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        console.log(category);
    },[category]);

    useEffect(() => {
        fetchCategories();
        fetchBrand();
      }, []);
    
    const fetchCategories = async () => {
        const data = await fetch (
            BE + '/api/category/all',
        );
    
        const items = await data.json();
        console.log(items)
        setCategories(categories => items);
    }

    const fetchBrand = async () => {
        const data = await fetch (
            BE + '/api/brand/all',
        );
    
        const items = await data.json();
        console.log(items)
        setBrands(brands => items);
    }

    const Dropzone = () => {
        
        const baseStyle = {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            borderWidth: 2,
            borderRadius: 2,
            borderColor: '#eeeeee',
            borderStyle: 'dashed',
            backgroundColor: '#fafafa',
            color: '#bdbdbd',
            outline: 'none',
            transition: 'border .24s ease-in-out',
            width : '300px'
        };
        
        const focusedStyle = {
            borderColor: '#2196f3'
        };
        
        const acceptStyle = {
            borderColor: '#00e676'
        };
        
        const rejectStyle = {
            borderColor: '#ff1744'
        };
        
        const onDrop = useCallback(acceptedFiles => {
            setFile(file => acceptedFiles[0]);
            console.log(acceptedFiles.length)
            const prev = URL.createObjectURL(acceptedFiles[0]);
            console.log(file);
            console.log(prev);
            setPreview(preview => prev)
        }, []);
        
        const {getRootProps, getInputProps} = useDropzone({onDrop, multiple: false, accept:"image/*"})
        return (
          <div style={baseStyle} {...getRootProps()}>
            <input {...getInputProps()} />
            Click aici pentru a adauga poza
          </div>
        )
    }

    const imgStyle = { 'width': '100%', 'maxWidth': '300px','height': 'auto', 'objectFit': 'cover' };

    const [submitMessage,setSubmitMessage] = useState();
    const [name, setName] = useState();
    const [brand, setBrand] = useState();
    const [description, setDescription] = useState();

    const uploadFile = (file, productId) => {
        const formData = new FormData();
        formData.append('file', file);
        axios.post(
            STORE + '/store/upload/' + productId,
            formData,
            {headers: { 'Authorization': basicAuth(Auth.getUser()), "Content-Type": "multipart/form-data" }}
        ).then( () => {
            console.log("file uploaded successfuly");
            navigate('/detail/'+productId);
        })
        .catch(function (error) {
            console.log(error);
            setSubmitMessage('Ups produsul a fost creat, dar imaginea nu a fost uploadata.');
            navigate('/detail/'+productId);
            /* Show an error here!*/
        });
    }

    const handleSubmit= (e) => {
        console.log("submitting..");
        setIsLoading(true);
        try {
            console.log(file);
            console.log(category);
            console.log(models);
            let correctForm = true;
            if (name == undefined) {
                correctForm = false;
            }
            if (file == undefined){
                correctForm = false;
            }
            if (brand.id == undefined) {
                correctForm = false;
            }
            if (description == undefined) {
                correctForm = false;
            }
            if (price == 0) {
                correctForm = false;
            }
            if (category.id == undefined) {
                correctForm = false;
            }
            if (models == undefined || models.length == 0) {
                correctForm = false;
            }
            if (correctForm) {

                axios.post(
                    BE + '/api/product/create', 
                    {
                        name: name,
                        brand: brand.id,
                        description: description,
                        price: price,
                        inventory: inventory,
                        status: status,
                        condition: condition,
                        tested: tested,
                        category: category.id,
                        compatibility: models
                    },
                    {headers: { 'Authorization': basicAuth(Auth.getUser()) }}
                ).then(function (response) {
                    if (file != undefined) {
                        uploadFile(file, response.data.id);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setSubmitMessage('Ups s-a produs o erroare la creearea produsului.');
                    /* Show an error here!*/
                });
                
            } else {
                setSubmitMessage('Unul dintre campuri nu a fost completat corescupanzator')
            }
            e.preventDefault();
        } catch (e) {
            setIsLoading(false);
            setSubmitMessage('S-a produs o eroare la crearea produsului');
        } finally {
            setIsLoading(false);
            // Nothing here yet
        }
    }

    const uploadProductForm = (
        <div className="bg-secondary-1">
            <form onSubmit={handleSubmit}>   
                <ul style={listStyle}>
                    <h1>Edit product</h1>
                    <li>
                        <label> Name <br></br>
                            <input type="text" value={name} onChange={e => setName(e.target.value)} />
                        </label>
                    </li>
                    <li>
                        <label>Brand
                            <DropdownList style={dropdownStyle}
                                valueFiled = {"id"}
                                textField = {"name"}
                                onChange={brand => setBrand(brand)}
                                data={brands}
                            />
                        </label>
                    </li>
                    <li>
                        <label> Description <br></br>
                            <input type="text" value={description} onChange={e => setDescription(e.target.value)} />
                        </label>
                    </li>
                    <li>
                        <label>Price
                            <NumberPicker
                                defaultValue={0}
                                min={0}
                                value={price}
                                onChange={price => setPrice(price)}
                                format={{ style: "currency", currency: "RON" }}/>
                        </label>
                    </li>
                    <li>
                        <label>Inventory
                            <NumberPicker
                                defaultValue={0}
                                min={0}
                                value={inventory}
                                onChange={inventory => setInventory(inventory)}/>
                        </label>
                    </li>
                    <li>
                        <label>Status
                            <DropdownList
                                value = {status}
                                onChange={status => setStatus(status)}
                                defaultValue="second-hand"
                                data={["second-hand", "new"]}
                            />
                        </label>
                    </li>
                    <li>
                        <label>Condition
                            <DropdownList
                                value = {condition}
                                onChange={condition => setCondition(condition)}
                                defaultValue="functional"
                                data={["functional", "defect"]}
                            />
                        </label>
                    </li>
                    <li>
                        <label>Category
                            <DropdownList style={dropdownStyle}
                                valueFiled = {"id"}
                                textField = {"name"}
                                onChange={category => setCategory(category)}
                                data={categories}
                            />
                        </label>
                    </li>
                    <li>
                        <label>Tested <br></br>
                            <Checkbox 
                                value = {tested}
                                onChange={tested => setTested(tested)}
                                defaultChecked={true}></Checkbox>
                        </label>
                    </li>
                    <li>
                        <label>Compatible models
                            <MultiselectWithCreate setModelsData={setModels}></MultiselectWithCreate>
                        </label>
                    </li>
                    <li>
                        <Dropzone></Dropzone>
                        <label>    
                            <img className='img-fluid w-100' style={imgStyle} src={preview}></img>
                        </label>
                    </li>
                    <li>
                        <input className="btn btn-primary py-2 px-4" type="submit" value="Submit" />
                        <p>{submitMessage}</p>
                    </li>
                </ul>
            </form>
        </div>
    );

    return (
        <div>
            {isLoading ? <LoadingSpinner/> : uploadProductForm}
        </div>
    );
}

export default UploadProduct;
