import React, {useCallback, useState, useEffect, useMemo } from 'react';
import "react-widgets/styles.css";
import 'react-widget-checkbox/style';
import { useParams } from 'react-router-dom';
import DropdownList from "react-widgets/DropdownList";
import DatePicker from "react-widgets/DatePicker";

import LoadingSpinner from "../components/loadingspinner";
import env from "react-dotenv";

import { basicAuth } from '../api/authapi';
import { useAuth } from '../context/AuthContext';

const axios = require('axios');

const listStyle = {"listStyleType" :"none"}

const BE = env.API_ENDPOINT;

function Order() { 

    const [order, setOrder] = useState({});
    const [clientInfo, setClientInfo] = useState({});
    const [address, setAddress] = useState({});
    const [orderItemList, setOrderItemList] = useState([]);

    const [status, setStatus] = useState("");
    const [awb, setAwb] = useState("");
    const [details, setDetails] = useState("");
    const [courier, setCourier] = useState("");
    const [submitMessage, setSubmitMessage] = useState("");

    const [isLoadingOrder, setIsLoadingOrder] = useState(false);

    const { id } = useParams();

    useEffect(() => {
        fetchItem();
    }, []);

    useEffect(() => {
        fetchOrderItems().then(()=> setIsLoadingOrder(false));
    }, [])

    const Auth = useAuth();

    const handleSubmit = () => {
        console.log("handle submit");
        console.log(courier);

        let requestBody = {status: status};
        requestBody.awb= awb;
        requestBody.details= details;
        requestBody.courier= courier;

        axios.post(
            BE + `/api/order/process/${id}`, 
            requestBody,
            {headers: { 'Authorization': basicAuth(Auth.getUser()) }}
        ).then(setSubmitMessage("Comanda a fost actualizata cu succes"))
        .catch(function (error) {
            console.log(error);
            setSubmitMessage('Ups s-a produs o erroare la procesarea comenzii.');
            /* Show an error here!*/
        });

    };

    const sendMail = () => {
        console.log("send mail");

        axios.post(
            BE + `/api/mail/order/${id}`, 
            undefined,
            {headers: { 'Authorization': basicAuth(Auth.getUser()) }}
        ).then(setSubmitMessage("Clientul a fost notificat"))
        .catch(function (error) {
            console.log(error);
            setSubmitMessage('Ups s-a produs o erroare la notificarea clientului.');
        });
    };

    const fetchItem = async () => {
        setIsLoadingOrder(true);
        console.log('fetching item...');
        
        console.log(id);
        if (id != undefined) {

            const data = await fetch (
                BE + `/api/order/${id}`,
                {headers: { 'Authorization': basicAuth(Auth.getUser()) }}
            );

            const item = await data.json();
            console.log("..item..");
            console.log(item);

            try {
                console.log(new Date(Date.parse(item.dateLastUpdate)));
            } catch(e) {
                item.dateLastUpdate = item.dateIssued;
            }

            setOrder(item);
            setStatus(item.status);
            setAwb(item.awb);
            setDetails(item.details);
            setCourier(item.courier);
            setClientInfo(item.clientInfo);
            setAddress(item.clientInfo.address);
        }
    }

    const fetchOrderItems = async () => {
        setIsLoadingOrder(true);
        console.log('fetching item...');
        
        console.log(id);
        if (id != undefined) {

            const data = await fetch (
                BE + `/api/order/items/${id}`,
                {headers: { 'Authorization': basicAuth(Auth.getUser()) }}
            );

            const item = await data.json();
            console.log("..orderitems..");
            console.log(item);
            setOrderItemList(item);
        }
    }
    
    const editOrder = (
        <div class="container-fluid pt-5 bg-secondary-1">
            <div class="row px-xl-5">
                <div class="col-lg-8">
                    <div class="mb-4">
                        <h4 class="font-weight-semi-bold mb-4">Adresa</h4>
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label>First Name</label>
                                <input class="form-control" type="text" disabled value={clientInfo.firstName}/>
                            </div>
                            <div class="col-md-6 form-group">
                                <label>Last Name</label>
                                <input class="form-control" type="text" disabled value={clientInfo.lastName}/>
                            </div>
                            <div class="col-md-6 form-group">
                                <label>E-mail</label>
                                <input class="form-control" type="text" disabled value={clientInfo.email}/>
                            </div>
                            <div class="col-md-6 form-group">
                                <label>Mobile No</label>
                                <input class="form-control" type="text" disabled value={clientInfo.telephone}/>
                            </div>
                            <div class="col-md-6 form-group">
                                <label>Address Line 1</label>
                                <input class="form-control" type="text" disabled value={address.street}/>
                            </div>
                            <div class="col-md-6 form-group">
                                <label>Country</label>
                                <input class="form-control" type="text" disabled value={address.country}/>
                            </div>
                            <div class="col-md-6 form-group">
                                <label>City</label>
                                <input class="form-control" type="text" disabled value={address.city}/>
                            </div>
                            <div class="col-md-6 form-group">
                                <label>State</label>
                                <input class="form-control" type="text" disabled value={address.county}/>
                            </div>
                            <div class="col-md-6 form-group">
                                <label>ZIP Code</label>
                                <input class="form-control" type="text" disabled value={address.zipCode}/>
                            </div>
                        </div>
                        <div class="row"><div class="col-md-12 form-group">
                            <label>Detalii comandă</label>
                        <input class="form-control" type="text" disabled value={order.clientDetails}/>
                    </div></div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="card border-secondary mb-5">
                        <div class="card-header bg-secondary border-0">
                            <h4 class="font-weight-semi-bold m-0">Comanda</h4>
                        </div>
                        <div class="card-body">
                            <h5 class="font-weight-medium mb-3">Products</h5>
                            {
                                orderItemList.map(
                                    oi => (
                                        <div class="d-flex justify-content-between">
                                            <p>{oi.product.name}</p>
                                            <p>{oi.product.price} RON</p>
                                        </div>
                                    )
                                )
                            }
                            <hr class="mt-0"/>
                            
                            <div class="d-flex justify-content-between">
                                <h6 class="font-weight-medium">Transport</h6>
                                <h6 class="font-weight-medium">Depinde de locatia clientului</h6>
                            </div>
                        </div>
                        <div class="card-footer border-secondary bg-transparent">
                            <div class="d-flex justify-content-between mt-2">
                                <h5 class="font-weight-bold">Total</h5>
                                <h5 class="font-weight-bold">Subtotal + transport</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row px-xl-5">
                <div class="col-lg-8">
                    <h4 class="font-weight-semi-bold mb-4">Proceseaza comanda</h4>
                    <div class="mb-4">
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label>AWB</label>
                                <input class="form-control" type="text" value={awb} onChange={e => setAwb(e.target.value)} />
                            </div>
                            <div class="col-md-6 form-group">
                                <label>Status</label>
                                <DropdownList class="form-control"
                                    value = {status}
                                    onChange={status => setStatus(status)}
                                    defaultValue="Pending"
                                    data={["Pending", "Sent", "Confirmed", "Declined", "Returned"]}
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label>Data plasarii</label>
                                    <DatePicker disabled class="form-control"
                                        value = {new Date(Date.parse(order.dateIssued))}
                                        // onChange={() => {}} 
                                        placeholder="m/dd/yy" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label>Ultima actualizare</label>
                                    <DatePicker disabled class="form-control"
                                        value = {new Date(Date.parse(order.dateLastUpdate))}
                                        // onChange={() => {}} 
                                        placeholder="m/dd/yy" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 form-group">
                                <label>Curier</label>
                                    <DropdownList
                                        value = {courier}
                                        onChange={courier => setCourier(courier)}
                                        defaultValue="AltaModalitateDeTransport"
                                        data={["AltaModalitateDeTransport", 
                                            "PostaRomanaPriorityPost",
                                            "Sameday",
                                            "Cargus",
                                            "FanCourier"]}
                                    />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 form-group">
                                <label>Detalii comanda</label>
                                <input className='form-control' type="text" value={details} onChange={e => setDetails(e.target.value)} />
                            </div>
                        </div>
                        <div class="row">
                            <div class="card-footer border-secondary bg-transparent">
                                <input class="btn btn-lg btn-block btn-primary font-weight-bold my-3 py-3" 
                                    type="submit" 
                                    value="Actualizeaza comanda" 
                                    onClick={handleSubmit}/>
                            </div>
                            <div class="card-footer border-secondary bg-transparent">
                                <input class="btn btn-lg btn-block btn-primary font-weight-bold my-3 py-3" 
                                    type="submit" 
                                    value="Notifica client" 
                                    onClick={sendMail}/>
                            </div>
                        </div>
                        <div class="row">
                            <p>{submitMessage}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const orderProcessingForm = (
        <></>
    )

    return (
        <div>
            {isLoadingOrder ? <LoadingSpinner/> : editOrder}
        </div>
    );
}

export default Order;
