import React, {useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import env from "react-dotenv";
import { DataGrid } from '@mui/x-data-grid';
import LoadingSpinner from "../components/loadingspinner";
import { basicAuth } from '../api/authapi';
import { useAuth } from '../context/AuthContext';
import Card from 'react-bootstrap/Card';
import Column from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

const axios = require('axios');

const BE = env.API_ENDPOINT;

function Home() {

  const [isLoadingOrders, setIsLoadingOrders] = useState(false);

  const Auth = useAuth();

  useEffect(() => {

    const fetchItems = async () => {
        setIsLoadingOrders(true);
        const data = await fetch (
            BE + '/api/order/all',
            {headers: { 'Authorization': basicAuth(Auth.getUser()) }}
        );

        const response = await data.json();

        console.log(response);

        setOrders(response);

        setOrdersConfirmed(getOrdersNumberByStatus(response, 'Confirmed'));
        setOrdersSent(getOrdersNumberByStatus(response, "pending"));
        setOrdersPending(getOrdersNumberByStatus(response, "Sent"));
        setOrdersDeclined(getOrdersNumberByStatus(response, "Declined"));
        setLastOrderDate(response[0].dateIssued);

    }

    fetchItems().then(() => setIsLoadingOrders(false)).catch(console.error);

  }, []);

  const getOrdersNumberByStatus = (o, s) => {
    let size = 0;
    o.map((order) => {if(order.status === s) size++});
    return size;
  }

    const getClientInfo = (m) => {

      let clInfo = '';

      if (m.clientInfo != undefined && m.clientInfo.email != undefined) {
        clInfo = m.clientInfo.email;
      }

      return clInfo;
    }

    const [orders, setOrders] = useState([]);
    const [ordersPending, setOrdersPending] = useState(0);
    const [ordersConfirmed, setOrdersConfirmed] = useState(0);
    const [ordersSent, setOrdersSent] = useState(0);
    const [ordersDeclined, setOrdersDeclined] = useState(0);
    const [lastOrderDate, setLastOrderDate] = useState(undefined);


    const statsDiv = (
      <div>
      {isLoadingOrders || orders == undefined ? <LoadingSpinner /> : 
              <div>
                  <Card class="bg-secondary-1 d-flex justify-content-between mb-3 pt-1">

                  <Card.Header>
                  <h4>Statistici comenzi</h4>
                  </Card.Header>

                  <Card.Body className="bg-secondary-1">
                      <Row>
                          <Column>
                            <label>Numar comenzi</label>
                            <h4>{orders.length}</h4>
                            <label>Ultima comanda</label>
                            <h4>{lastOrderDate}</h4>
                          </Column>
                          <Column>
                            <label>Comenzi in asteptare</label>
                            <h4>{ordersPending}</h4>
                            <label>Comenzi confirmate</label>
                            <h4>{ordersConfirmed}</h4>
                          </Column>
                          <Column>
                            <label>Comenzi refuzate</label>
                            <h4>{ordersDeclined}</h4>
                            <label>Comenzi trimise</label>
                            <h4>{ordersSent}</h4>
                          </Column>
                      </Row>
                  </Card.Body>
                  
                  <Card.Footer>
                  </Card.Footer>
                  </Card>
                  <hr></hr>
                  </div>
              
          
          }
      </div>
    );

    const ordersDiv = (
      <div>
          {isLoadingOrders || orders == undefined ? <LoadingSpinner /> : orders.map((m, i) => (
              <div>
                  <Card class="d-flex justify-content-between mb-3 pt-1">

                  <Card.Header>
                  <h4>{m.id}</h4>
                  </Card.Header>

                  <Card.Body>
                      <Row>
                          <Column>
                          <label>Data comenzii</label>
                              <h4>{m.dateIssued}</h4>
                          <label>Ultima modificare</label>
                              <h4>{m.dateLastUpdate}</h4>
                          </Column>
                          <Column>
                              <label>Status: </label>
                              <h4>{m.status}</h4>
                              <label>Email: </label>
                              <h4>{getClientInfo(m)}</h4>
                          </Column>
                          <Column>

                              <label>Inventar: 
                                <h4>Data comenzii: {m.status}</h4>
                              </label>
                          </Column> 

                      </Row>
                  </Card.Body>
                  
                  <Card.Footer>
                      <Button><Link style={{ 'color': 'inherit', 'textDecoration': 'inherit' }} to={`/order/${m.id}`}>Proceseaza comanda</Link></Button>
                  </Card.Footer>
                  </Card>
                  <hr></hr>
                  </div>
              
          ))
          }
      </div>                
);

    return (
      <div>
          {isLoadingOrders ? <LoadingSpinner/> : statsDiv}
          {isLoadingOrders ? <LoadingSpinner/> : ordersDiv}
      </div>
    );

    
}

export default Home;