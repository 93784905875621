import { findByLabelText } from '@testing-library/react';
import React from 'react'
import { Link } from 'react-router-dom';

let logoStyle2 = {'color': 'white'};

function Nav() {    
    return (
        <div className="container-fluid mb-5 bg-important-1">
            <div className="row align-items-center py-3 px-xl-5 bg-important-1">
                <div className="col-lg-3 d-none d-lg-block">
                <div className='inverted-3'>
                    <a href="" className="textDecoration-none" >
                        <h2 className="m-0 display-5 font-weight-semi-bold"><span className="text-primary font-weight-bold px-3 mr-1 ">TGV Service Lead</span></h2>
                    </a>
                </div>
                </div>
                <div className="col-lg-6 col-6 text-left">
                </div>
                <div className="col-lg-3 col-6 text-right">
                </div>
            </div>
            <div className="row row-nav border-top px-xl-5">
                <div className="col-lg-12">
                    <nav className="navbar navbar-expand-lg bg-important-1 navbar-light py-3 py-lg-0 px-0">
                    <a href="" className="textDecoration-none d-block d-lg-none">
                    <h1 className="mb-4 display-5 font-weight-semi-bold">
                        <span className="text-primary font-weight-bold px-3 mr-1 hover-1">TGV Service Lead</span>
                    </h1>
                </a>
                <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                        <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                            <div className="navbar-nav mr-auto py-0 bg-important-1">
                                <li className="nav-item nav-link"><Link className='nav-text' to='/orders'>Comenzi</Link></li>
                                <li className="nav-item nav-link"><Link className='nav-text' to='/products'>Produse</Link></li>
                                <li className="nav-item nav-link"><Link className='nav-text' to='/uploadproduct'>Adauga produs</Link></li>
                                <li className="nav-item nav-link"><Link className='nav-text' to='/uploadcategory'>Adauga categorie</Link></li>
                                <li className="nav-item nav-link"><Link className='nav-text' to='/uploadbrand'>Adauga brand</Link></li>
                                <li className="nav-item nav-link"><Link className='nav-text' to='/uploadmodel'>Adauga model</Link></li>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default Nav;