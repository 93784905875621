import axios from 'axios'
import env from "react-dotenv";

export const authApi = {
  authenticate
}

function authenticate(username, password) {
    console.log("authenticate");
    console.log("usr: " + username);
    console.log("pwd:" + password);
    return instance.post('/api/auth/authenticate', { username, password }, {
      headers: { 'Content-type': 'application/json' }
    })
}

// -- Axios

const BE = env.API_ENDPOINT;

const instance = axios.create({
    baseURL: BE + ""
});

// -- Helper functions

export function basicAuth(user) {
    return `Basic ${user.authdata}`
}