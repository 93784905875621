import "./spinner.css";
import React from "react";

function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
    </div>
  );
};

export default LoadingSpinner;