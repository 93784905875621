import React, {useState, useEffect} from 'react';
import "react-widgets/styles.css";
import 'react-widget-checkbox/style';
import env from "react-dotenv";
import { Link } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { useAuth } from '../context/AuthContext';
import { useContext } from 'react';
import LoadingSpinner from "../components/loadingspinner";
import NumberPicker from "react-widgets/NumberPicker";
import { findByDisplayValue } from '@testing-library/react';
import Card from 'react-bootstrap/Card';
import Column from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

import { basicAuth } from '../api/authapi';

const axios = require('axios');

const listStyle = {"listStyleType" :"none"}
const buttonStyle = {"background-color": "button", "text": "white"}
const navStyle = {"overflowX": "scroll","align-self":"center"};

const BE = env.API_ENDPOINT;


function Products() {

    let contextType = AuthContext
    console.log("contextType");
    console.log(contextType);
    let uc = useContext(contextType);
    console.log("uc: ");
    console.log(uc);

    const onChangeHandler = async (text) => {
        console.log("Filter text changed");
        setTextFilter(filter => text);
    }

    const [textFilter, setTextFilter] = useState("");

    const inputStyle = {"width": "100%"}


    const [products, setProducts] = useState([]);
    const [isLoadingProducts, setIsLoadingProducts] = useState(true);
    const [pageInfo, setPageInfo] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const Auth = useAuth();
    const [submitMessage,setSubmitMessage] = useState("..Incarc produsele..");


    function getLiClassName(i) {

        if (i == pageInfo.page) {
            return "page-item active"
        }

        return "page-item"
    }

    const fetchItems = async (i) => {

        console.log("...Fetching products...");

        let endpoint = '/api/product/all/with/models?size=10000&page=' + 0;
        console.log("-->" + endpoint)

        setIsLoadingProducts(true);
        if (textFilter !== "") {
            endpoint += '&name=' + textFilter;
        }

        axios.get(
            BE + endpoint,
            {headers: { 'Authorization': basicAuth(uc.getUser()) }}
        ).then((response) => setProducts(response.data.products)).then(setIsLoadingProducts(false)).then(setSubmitMessage("Produsele sunt incarcate."));
    }

    useEffect(() => {
        console.log("Products trigger");
        fetchItems().then(()=>setIsLoadingProducts(false));
    }, [textFilter]);

    useEffect(() => {
        fetchItems().then(() => { 
            console.log("loaded products.."); 
            console.log(products);
        }).catch(console.error);  
    }, []);

    const navBar = (
        <div className="row pb-3">
            <div className="col-12 pb-1">
                <nav>
                    <ul style={navStyle} class="pagination pagination-lg">
                    <ul class="pagination pagination-lg">
                        <li disabled= "true" class="page-item">
                            <a disabled= "true" class="page-link" href="#" tabindex={pageInfo.page}>Previous</a>
                        </li>
                    </ul>
                    <ul style={navStyle} class="pagination pagination-lg">
                        {[...Array(pageInfo.totalPages)].map((_,i) =>
                            <li className={getLiClassName(i)} active={i == pageInfo.page}>
                                <a className="page-link" onClick={() => fetchItems(i).then(()=>setIsLoadingProducts(false))}>{i}</a>
                            </li>
                        )}
                    </ul>
                    <ul class="pagination pagination-lg">
                        <li disabled= "true" class="page-item">
                            <a disabled= "true" class="page-link" href="#">Next</a>
                        </li>
                    </ul>
                    </ul>
                    
                </nav>
            </div>
        </div>
    );

    const textFilterDiv = (
        <div className="row pb-3">
            <div className="col-12 pb-1">
                <div>
                    <h4>{submitMessage}</h4>
                    <form action="">
                        <div  className="search">
                            <input type='text' 
                                    style={inputStyle}
                                    className="search-form"
                                    placeholder='Caută după produs sau model compatibil' 
                                    onChange={e => onChangeHandler(e.target.value)}>        
                            </input>
                        </div>
                    </form>
                    <div className="dropdown ml-4">
                    </div>
                </div>
            </div>
        </div>
    );

    const updateInventory = async (i, e) => {
        {
                console.log(e);
                let ui = products;
                ui[i].product.inventory = e;
                setProducts([...ui]);
                console.log("p: " + products[i].product.inventory);
                
        }
    }

    const updatePrice = async (i, e) => {
        {
                console.log(e);
                let up = products;
                up[i].product.price = e;
                setProducts([...up]);
                console.log("p: " + products[i].product.price);
        }
    }

    const getPrice = (i) => {
        return products[i].product.price;
    }

    const getInventory = (i) => {
        return products[i].product.inventory;
    }

    const handleSave = (i) => {
            let product = products[i].product;
            let price = product.price;
            let inventory = product.inventory;

            let endpoint = `/api/product/updateQP/${product.id}`;
            axios.put(
                BE + endpoint,
                {
                    "inventory":inventory,
                    "price":price
                },
                {headers: { 'Authorization': basicAuth(Auth.getUser()) }}
            ).then(function (response) {
                setSubmitMessage("Produsul: " + product.name + " a fost modificat");
                // console.log(response.status);
            })
            .catch(function (error) {
                setSubmitMessage("S-a produs o eroare in timpul procesarii.");
                console.log(error);
            });
    }

    const productsDiv = (
                <div>
                    {isLoadingProducts || products == undefined ? <LoadingSpinner /> : products.map((m, i) => (
                        <div>
                            <Card class="d-flex justify-content-between mb-3 pt-1">

                            <Card.Header>
                            <h4>{m.product.name} / </h4> <p>{m.product.id}</p>
                            </Card.Header>

                            <Card.Body>
                                <Row>
                                    <Column>
                                        <h4>Adaugat la: {m.product.dateAdded}</h4>
                                        <h4>Modificat la: {m.product.dateModified}</h4>
                                    </Column>
                                    <Column>

                                        <label>Pret: 
                                            <NumberPicker id={m.product.id}
                                                defaultValue={0}
                                                min={0}
                                                value={getPrice(i)}
                                                onChange={val => updatePrice(i,val).then(console.log(products))}
                                            />
                                        </label>
                                    </Column>
                                    <Column>

                                        <label>Inventar: 
                                            <NumberPicker id={m.product.id}
                                                defaultValue={0}
                                                min={0}
                                                value={getInventory(i)}
                                                onChange={val => updateInventory(i,val).then(console.log(products))}
                                            />
                                        </label>
                                    </Column>

                                </Row>
                            </Card.Body>
                            
                            <Card.Footer>
                                <Button onClick={()=>handleSave(i)}>Salveaza modificarile</Button>
                                <Button><Link style={{ 'color': 'inherit', 'textDecoration': 'inherit' }} to={`/editproduct/${m.product.id}`}>Editeaza produs</Link></Button>
                            </Card.Footer>
                            </Card>
                            <hr></hr>
                            </div>
                        
                    ))
                    }
                </div>                
    );

    return (
        <div className="container-fluid pt-5">
            <div> 
                    <div class="card-header bg-secondary border-0">
                        <h4 class="font-weight-semi-bold m-0">Produse</h4>
                    </div>
            {textFilterDiv}
            { isLoadingProducts ? <LoadingSpinner /> : productsDiv }
        </div>
        </div>
    );
}

export default Products;