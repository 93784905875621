import React, {useState, useEffect} from 'react';
import "react-widgets/styles.css";
import 'react-widget-checkbox/style';
import env from "react-dotenv";
import Checkbox from 'react-widget-checkbox';
import { Button } from '@mui/material';

import { basicAuth } from '../api/authapi';
import { useAuth } from '../context/AuthContext';

const axios = require('axios');

const listStyle = {"listStyleType" :"none"}
const buttonStyle = {"background-color": "button", "text": "white"}
const navStyle = {"overflowX": "scroll","align-self":"center"};

const BE = env.API_ENDPOINT;


function UploadModel() {

    const [name, setName] = useState();
    const [ModelId, setModelId] = useState({});
    const [models, setModels] = useState([]);
    const [m, setM] = useState({});
    const [isLoadingModels, setIsLoadingModels] = useState(false);
    const [pageInfo, setPageInfo] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [submitMessage,setSubmitMessage] = useState("");
    const Auth = useAuth();

    function getLiClassName(i) {

        if (i == pageInfo.page) {
            return "page-item active"
        }

        return "page-item"
    }

    const fetchModels = async (i) => {
        if (i == undefined ) {
            i = 0;
        }
        console.log("Page:" + i);
        setCurrentPage( i );

        setIsLoadingModels(true);
        const data = await fetch (
            BE + "/api/model/all/paged?size=12&page=" + i,
            {headers: { 'Authorization': basicAuth(Auth.getUser()) }}
        );

        let items = await data.json();
        console.log(items);
        console.log(i);
        setModels(items.content);

        let pageInfo = {};

        pageInfo["totalPages"]=items.totalPages;
        console.log(pageInfo.totalPages);
        pageInfo["totalElements"]=items.totalElements;
        pageInfo["page"]=items.page;
        pageInfo["size"]=items.size;

        setPageInfo(pageInfo);
        setM(m);
    }

    useEffect(() => {
        fetchModels().then(() => { 
            console.log("loaded models.."); 
            console.log(models);
            console.log(m);
            setIsLoadingModels(false);
        }).catch(console.error);  
    }, []);

    

    const handleSubmit= (e) => {
        console.log("submitting..");
        var CategoryId = undefined;
        axios.post(
            BE + '/api/model/create', 
            {
                name: name
            },
            {headers: { 'Authorization': basicAuth(Auth.getUser()) }},
        ).then(setSubmitMessage("Modelul nou a fost creat cu succes")).catch(function (error) {
            setSubmitMessage("S-a produs o eroare la crearea modelului");
            console.log(error);
        });

        e.preventDefault();
    }

    const navBar = (
        <div className="row pb-3">
            <div className="col-12 pb-1">
                <nav>
                    <ul style={navStyle} class="pagination pagination-lg">
                    <ul class="pagination pagination-lg">
                        <li disabled= "true" class="page-item">
                            <a disabled= "true" class="page-link" href="#" tabindex={pageInfo.page}>Previous</a>
                        </li>
                    </ul>
                    <ul style={navStyle} class="pagination pagination-lg">
                        {[...Array(pageInfo.totalPages)].map((_,i) =>
                            <li className={getLiClassName(i)} active={i == pageInfo.page}>
                                <a className="page-link" onClick={() => fetchModels(i).then(()=>setIsLoadingModels(false))}>{i}</a>
                            </li>
                        )}
                    </ul>
                    <ul class="pagination pagination-lg">
                        <li disabled= "true" class="page-item">
                            <a disabled= "true" class="page-link" href="#">Next</a>
                        </li>
                    </ul>
                    </ul>
                    
                </nav>
            </div>
        </div>
    );

    return (
        <div>

            <div> 
                <form onSubmit={handleSubmit} className="bg-secondary-1">
                    
                    <ul style={listStyle}>
                        <h1>Adauga model</h1>
                        <li>
                            <label> Name <br></br>
                                <input type="text" value={name} onChange={e => setName(e.target.value)} />
                            </label>
                        </li>
                        <li>
                            <input className="btn btn-primary py-2 px-4" type="submit" value="Submit" />
                            <p>{submitMessage}</p>
                        </li>
                    </ul>
                </form>
                <ul>
                    <h1>Modelele existente</h1>
                    {isLoadingModels || models == undefined ? <div></div> : models.map((m) => (
                    <li className="row pb-3">
                        {/* <Button style={buttonStyle}>Update</Button> */}
                        {/* <p>{m.id}</p> */}
                        <p>{m.name}</p>
                        {/* <Button style={buttonStyle}>Delete</Button> */}
                    </li>
                ))
                }
                </ul>
            </div>
            {navBar}
        </div>
    );
}

export default UploadModel;