import React, {useState} from 'react';
import "react-widgets/styles.css";
import 'react-widget-checkbox/style';
import env from "react-dotenv";

import { basicAuth } from '../api/authapi';
import { useAuth } from '../context/AuthContext';

const axios = require('axios');

const listStyle = {"listStyleType" :"none"}

const BE = env.API_ENDPOINT;

function UploadCategory() {

    const [name, setName] = useState(undefined);
    const [description, setDescription] = useState(undefined);
    const [submitMessage, setSubmitMessage] = useState("");
    const Auth = useAuth();

    const handleSubmit= (e) => {
        var CategoryId = undefined;
        axios.post(
            BE + '/api/category/create', 
            {
                name: name,
                description: description
            },
            {headers: { 'Authorization': basicAuth(Auth.getUser()), 'Content-Type': 'application/json' }},
        ).then(setSubmitMessage("Categoria a fost adaugata cu succes.")).catch(function (error) {
            console.log(error);
            setSubmitMessage("S-a produs o eroare la crearea categoriei.");
        });

        e.preventDefault();
    }

    return (
        <div>
            
            <form onSubmit={handleSubmit} className="bg-secondary-1">
                
                <ul style={listStyle}>
                    <h1>Adauga categorie</h1>
                    <li>
                        <label> Name <br></br>
                            <input type="text" value={name} onChange={e => setName(e.target.value)} />
                        </label>
                    </li>
                    <li>
                        <label> Description <br></br>
                            <input type="text" value={description} onChange={e => setDescription(e.target.value)} />
                        </label>
                    </li>
                    <li>
                        <input className="btn btn-primary py-2 px-4" type="submit" value="Submit" />
                        <p>{submitMessage}</p>
                    </li>
                </ul>
            </form>
        </div>
    );
}

export default UploadCategory;