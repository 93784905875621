import React, {useCallback, useState, useEffect, useMemo } from 'react';
import {useNavigate} from 'react-router-dom';
import {useDropzone} from 'react-dropzone'
import "react-widgets/styles.css";
import NumberPicker from "react-widgets/NumberPicker";
import DropdownList from "react-widgets/DropdownList";
import {Checkbox} from 'react-widget-checkbox';
import 'react-widget-checkbox/style';
import MultiselectWithCreate from '../components/modelMultiSelectWithCreate';
import { useParams } from 'react-router-dom';
import pic from '../NoImageAvailable.png'
import LoadingSpinner from "../components/loadingspinner";
import env from "react-dotenv";
import { basicAuth } from '../api/authapi';
import { useAuth } from '../context/AuthContext';

const axios = require('axios');

const listStyle = {"listStyleType" :"none"}

const BE = env.API_ENDPOINT;
const STORE = env.STORE_ENDPOINT;

function EditProduct() {
    const navigate = useNavigate();
    const [product, setProduct] = useState({});
    const [file, setFile] = useState({});
    const [preview, setPreview] = useState(null);
    const [price, setPrice] = useState(0);
    const [inventory, setInventory] = useState(0);
    const [condition, setCondition] = useState('functional');
    const [status, setStatus] = useState('second-hand');
    const [tested, setTested] = useState(true);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState({});
    const [models, setModels] = useState([]);
    const [fileChanged, setFileChanged] = useState(false);
    const [brands, setBrands] = useState([]);


    const [isLoadingProduct, setIsLoadingProduct] = useState(false);
    const [isLoadingCategories, setIsLoadingCategories] = useState(false);

    const { id } = useParams();

    const Auth = useAuth();

    useEffect(() => {
        console.log("fetch my items ");
        fetchItem().then(()=> setIsLoadingProduct(false));
    }, []);

    useEffect(() => {

        fetchCategories().then(fetchBrand()).then(()=>{setIsLoadingCategories(false)});
    }, []);

    useEffect(() => 
    {
        if (categories != undefined 
            && product != undefined 
            && product != {} && categories != [] 
            && product.product != undefined 
            && product.product.catalogueList != undefined
            && product.product.catalogueList.length > 0) {
                for (var i = 0; i < categories.length; i++) {
                    const cat = categories[i];
                    if (cat.id == product.product.catalogueList[0].category) {
                        setCategory(cat);
                        break;
                    }
                }

                for (var i = 0; i < brands.length; i++) {
                    const bat = brands[i];
                    if (bat.id == product.product.brandCatalogueList[0].brand) {
                        setBrand(bat);
                        break;
                    }
                }
        }
    }, [product, categories]);

    const fetchItem = async () => {
        setIsLoadingProduct(true);
        console.log('fetching item...');
        
        console.log(id);
        if (id != undefined) {

            const data = await fetch (
                BE + `/api/product/${id}`,
                {headers: { 'Authorization': basicAuth(Auth.getUser()) }}
            );

            const item = await data.json();
            console.log("..item..");
            console.log(item);
            
            setProduct(item);
            setName(item.product.name);
            setBrand(item.product.brand);
            setDescription(item.product.description);
            setCondition(item.product.condition);
            setStatus(item.product.status);
            setPrice(item.product.price);
            setInventory(item.product.inventory);
            setTested(item.product.tested);
            setModels(item.compatibleModels);

            if(item.product.images == undefined || item.product.images[0] == undefined) {
                item.img = pic;
            }
            else {
                item.img = STORE + '/store/download/' + item.product.images[0].id;
            }

            setPreview(item.img);
        } else {
            console.log("id: " + id);
        }
    }

    const fetchBrand = async () => {
        const data = await fetch (
            BE + '/api/brand/all',
        );
    
        const items = await data.json();
        console.log(items)
        setBrands(brands => items);
    }
    
    const fetchCategories = async () => {
        setIsLoadingCategories(true);
        const data = await fetch (
            BE + '/api/category/all',
            {headers: { 'Authorization': basicAuth(Auth.getUser()) }}
        );
    
        const items = await data.json();
        console.log("loaded cats");
        console.log(items);
        setCategories(categories => items);
    }

    const Dropzone = () => {
        
    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        width : '300px'
    };
    
    const focusedStyle = {
        borderColor: '#2196f3'
    };
    
    const acceptStyle = {
        borderColor: '#00e676'
    };
    
    const rejectStyle = {
        borderColor: '#ff1744'
    };
        
        const onDrop = useCallback(acceptedFiles => {
            setFile(file => acceptedFiles[0]);
            
            const prev = URL.createObjectURL(acceptedFiles[0]);
            setFileChanged(true);
            setPreview(preview => prev)
        }, []);
        
        const {getRootProps, getInputProps} = useDropzone({onDrop, multiple: false, accept:"image/*"})
        return (
          <div style={baseStyle} {...getRootProps()}>
            <input {...getInputProps()} />
            Click aici pentru a adauga poza
          </div>
        )
    }

    const imgStyle = {  'width': '100%', 'maxWidth': '300px','height': 'auto',  'objectFit': 'cover'  };
    const buttonPadding = { 'margin': '5px' };
    const [submitMessage,setSubmitMessage] = useState();
    const [name, setName] = useState();
    const [brand, setBrand] = useState();
    const [description, setDescription] = useState();

    const uploadFile = (file) => {
        const formData = new FormData();
        formData.append('file', file);
        axios.post(
            STORE + `/store/upload/${id}`,
            formData,
            {headers: { 'Authorization': basicAuth(Auth.getUser()), "Content-Type": "multipart/form-data" }}
        ).then( () => {
            console.log("file uploaded successfuly");
            navigate(`/detail/${id}`);
        })
        .catch(function (error) {
            console.log(error);
            setSubmitMessage('Ups produsul a fost creat, dar imaginea nu a fost uploadata.');
            /* Show an error here!*/
        });
    }

    const handleSubmit= (e) => {
        console.log("submitting..");
        setIsLoadingProduct(true);

        try {
            console.log(file);
            console.log(category);
            console.log(models);
            let correctForm = true;
            if (name == undefined) {
                correctForm = false;
            }
            if (file == undefined){
                correctForm = false;
            }
            if (brand.id == undefined) {
                correctForm = false;
            }
            if (description == undefined) {
                correctForm = false;
            }
            if (price == 0) {
                correctForm = false;
            }
            // if (inventory == 0) {
            //     correctForm = false;
            // }
            if (category.id == undefined) {
                correctForm = false;
            }
            if (models == undefined || models.length == 0) {
                correctForm = false;
            }
            if (correctForm) {

                axios.put(
                    BE + `/api/product/update/${id}`, 
                    {
                        name: name,
                        brand: brand.id,
                        description: description,
                        price: price,
                        inventory: inventory,
                        status: status,
                        condition: condition,
                        tested: tested,
                        category: category.id,
                        compatibility: models
                    },
                    {headers: { 'Authorization': basicAuth(Auth.getUser()) }}
                ).then(function (response) {
                    if (file != undefined && fileChanged) {
                        uploadFile(file);
                            /* Show an error here!*/
                    } else {
                            navigate(`/detail/${id}`);
                    }
                    
                })
                .catch(function (error) {
                    console.log(error);
                    setSubmitMessage('Ups s-a produs o erroare la creearea produsului.');
                    /* Show an error here!*/
                });
                
            } else {
                setSubmitMessage('Unul dintre campuri nu a fost completat corescupanzator.')
            }

        e.preventDefault();
        } catch(e)   { 
            // TO DO write there was an error...
            setSubmitMessage('S-a produs o eroare la editarea produsului.');
        } 
        finally {   
            setSubmitMessage('Produsul a fost editat cu succes.');
            setIsLoadingProduct(false);
        }

    }

    const deleteProduct = () => {
        console.log("deleteProduct");
        console.log(`${id}`);
        axios.delete(STORE + `/store/delete/${id}`,
            {headers: { 'Authorization': basicAuth(Auth.getUser()) }}).then(
        axios.delete(BE + `/api/product/delete/${id}`,
            {headers: { 'Authorization': basicAuth(Auth.getUser()) }})
        ).catch(function (error) {
            console.log(error);
            setSubmitMessage('Ups nu am reusit sa sterg imaginea.');
            /* Show an error here!*/
        }).then(function (response) {
            navigate('/shop/');
        })
        .catch(function (error) {
            console.log(error);
            setSubmitMessage('Ups nu am reusit sa sterg produsul.');
            /* Show an error here!*/
        });
    }


    const editProductForm = (
        <form onSubmit={handleSubmit} className="bg-secondary-1">
            <ul style={listStyle}>
                <h1>Edit product</h1>
                <li>
                    <label> Name <br></br>
                        <input type="text" value={name} onChange={e => setName(e.target.value)} />
                    </label>
                </li>
                <li>
                    <label>Brand
                        <DropdownList 
                            valueFiled = {"id"}
                            textField = {"name"}
                            value = {brand}
                            onChange={brand => setBrand(brand)}
                            data={brands}
                        />
                    </label>
                </li>
                <li>
                    <label> Description <br></br>
                        <input type="text" value={description} onChange={e => setDescription(e.target.value)} />
                    </label>
                </li>
                <li>
                    <label>Price
                        <NumberPicker
                            defaultValue={0}
                            min={0}
                            value={price}
                            onChange={price => setPrice(price)}
                            format={{ style: "currency", currency: "RON" }}/>
                    </label>
                </li>
                <li>
                    <label>Inventory
                        <NumberPicker
                            defaultValue={0}
                            min={0}
                            value={inventory}
                            onChange={inventory => setInventory(inventory)}/>
                    </label>
                </li>
                <li>
                    <label>Status
                        <DropdownList
                            value = {status}
                            onChange={status => setStatus(status)}
                            defaultValue="second-hand"
                            data={["second-hand", "new"]}
                        />
                    </label>
                </li>
                <li>
                    <label>Condition
                        <DropdownList
                            value = {condition}
                            onChange={condition => setCondition(condition)}
                            defaultValue="functional"
                            data={["functional", "defect"]}
                        />
                    </label>
                </li>
                <li>
                    <label>Category
                        <DropdownList
                            valueFiled = {"id"}
                            textField = {"name"}
                            value = {category}
                            onChange={category => setCategory(category)}
                            data={categories}
                        />
                    </label>
                </li>
                <li>
                    <label>Tested <br></br>
                        <Checkbox 
                            value = {tested}
                            onChange={tested => setTested(tested)}
                            defaultChecked={true}></Checkbox>
                    </label>
                </li>
                <li>
                    <label>Compatible models
                        <MultiselectWithCreate setModelsData={setModels}
                        values = {models}
                        />
                    </label>
                </li>
                <li>
                    <Dropzone></Dropzone>
                    <label>
                        <img className='img-fluid w-100' style={imgStyle} src={preview}></img>
                    </label>
                </li>
                <li>
                    <input className="btn btn-primary py-2 px-4" style={buttonPadding} type="submit" value="Submit" />
                    <input className="btn btn-primary py-2 px-4" type="button" onClick={() => deleteProduct()} value="Delete"/>
                </li>
                <li><p>{submitMessage}</p></li>
            </ul>
        </form>
    );

    return (
        <div>
            {isLoadingProduct && isLoadingCategories ? <LoadingSpinner/> : editProductForm}
        </div>
    );
}

export default EditProduct;
