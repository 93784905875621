import React, { Component, useEffect, useState } from 'react'
import { NavLink, Navigate } from 'react-router-dom'
import { Button, Form, Grid, Segment, Message } from 'semantic-ui-react'
import AuthContext from '../context/AuthContext'
import { authApi } from '../api/authapi'
import {useNavigate} from 'react-router-dom';
import { AuthProvider } from '../context/AuthContext'
import { useAuth } from '../context/AuthContext'

function Login () {

  const Auth = useAuth();

  console.log(Auth);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e, { name, value }) => {
    console.log("handleInputChange");
    console.log(name);
    console.log(value);
    if (name == "password") {
      setPassword(value);
      console.log("pwd set");
    } else {
      setUsername(value);
      console.log("usr set");
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("login");

    console.log(username);
    console.log(password);

    if (!(username && password)) {
      setIsError( true );
      return;
    }

    authApi.authenticate(username, password)
      .then(response => {
        const { id, name, role } = response.data
        const authdata = window.btoa(username + ':' + password)
        const user = { id, name, role, authdata }

        Auth.userLogin(user)

        setIsError(false);
        setIsLoggedIn(true);
        setUsername("");
        setPassword("");
      })
      .catch(error => {
        console.log(error)
        setIsError(true);
      })
  }

  if (isLoggedIn) {
    navigate('/');
  } else {
    return (
      <Grid textAlign='center'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Form size='large' onSubmit={handleSubmit}>
            <Segment>
              <Form.Input
                fluid
                autoFocus
                name='username'
                icon='user'
                iconPosition='left'
                placeholder='Username'
                onChange={handleInputChange}
              />
              <Form.Input
                fluid
                name='password'
                icon='lock'
                iconPosition='left'
                placeholder='Password'
                type='password'
                onChange={handleInputChange}
              />
              <Button color='blue' fluid size='large'>Login</Button>
            </Segment>
          </Form>
          {/* <Message>{`Don't have already an account? `} */}
            {/* <a href='/signup' color='teal' as={NavLink} to="/signup">Sign Up</a> */}
          {/* </Message> */}
          {isError && <Message negative>The username or password provided are incorrect!</Message>}
        </Grid.Column>
      </Grid>
    )
  }

}

export default Login;