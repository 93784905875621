import React, { Component, useContext } from 'react'

const AuthContext = React.createContext()

class AuthProvider extends Component {
  state = {
    user: undefined
  }

  componentDidMount() {
    console.log("componentDidMount");
    const user = localStorage.getItem('user')
    this.setState({ user })
  }

  getUser = () => {
    console.log("getUser");
    return JSON.parse(localStorage.getItem('user'))
  }

  userIsAuthenticated = () => {
    console.log("userIsAuthenticated");
    let user = localStorage.getItem('user') !== null; 
    console.log(user)
    return user;
  }

  userLogin = user => {
    console.log("userLogin");
    localStorage.setItem('user', JSON.stringify(user))
    this.setState({ user })
  }

  userLogout = () => {
    console.log("userLogout");
    localStorage.removeItem('user')
    this.setState({ user: null })
  }

  render() {
    console.log("render");
    const { children } = this.props
    const { user } = this.state
    const { getUser, userIsAuthenticated, userLogin, userLogout } = this

    return (
      <AuthContext.Provider value={{ user, getUser, userIsAuthenticated, userLogin, userLogout, }}>
        {children}
      </AuthContext.Provider>
    ) 
  }
}

export default AuthContext

export function useAuth() {
  return useContext(AuthContext);
}

export { AuthProvider }