import React, {useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import pic from '../NoImageAvailable.png'
import env from "react-dotenv";
import { basicAuth } from '../api/authapi';
import { useAuth } from '../context/AuthContext';

const BE = env.API_ENDPOINT;
const STORE = env.STORE_ENDPOINT;

function Detail() {
    const { id } = useParams();
    const Auth = useAuth();

    useEffect(() => {
        fetchItem();
        
    }, []);

    const [item, setItem] = useState({});
    const [product, setProduct] = useState({});
    const [compatibleModels, setCompatibleModels] = useState([]);

    const fetchItem = async () => {
        console.log('fetching item...');
        
        console.log(id);
        const data = await fetch (
            BE + `/api/product/${id}`,
            {headers: { 'Authorization': basicAuth(Auth.getUser()) }}
        );

        const item = await data.json();
        console.log(item);

        setProduct(p => item.product);
        setCompatibleModels(cm => item.compatibleModels);

        if(item.product.images == undefined || item.product.images[0] == undefined) {
            item.img = pic;
        }
        else {
            item.img = STORE + '/store/download/' + item.product.id + '?resolution=Low';
        }
        setItem(item);
    }

    const handleChange = () => { console.log('do nothing')}

    const imgStyle = { 'objectFit': 'cover', 'height': '400px'}

    return (
        <div>
            <div key={product.id} className="container-fluid py-5 bg-secondary-1">
                <div className="row px-xl-5">
                    <div className="col-lg-5 pb-5">
                        <div id="product-carousel" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner border">
                                <div className="carousel-item active">
                                    <img className="w-100 h-100" src={item.img}/>    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-7 pb-5">
                        <h3 className="font-weight-semi-bold">{product.name}</h3>
                        <h6 className="text-truncate mb-3">{product.inventory < 1 ? "Out of stock" : ""}</h6>
                        <h3 className="font-weight-semi-bold mb-4">{product.price} RON</h3>
                        <p className="mb-4">{product.brand}</p>
                        
                        <div className="d-flex align-items-center mb-4 pt-2">
                            <div className="input-group quantity mr-3" style={{'width': '130px'}}>
                                <div className="input-group-btn">
                                    <button className="btn btn-primary btn-minus" >
                                    <i className="fa fa-minus"></i>
                                    </button>
                                </div>
                                <input type="text" className="form-control bg-secondary text-center" value="1" onChange={handleChange}/>
                                <div className="input-group-btn">
                                    <button className="btn btn-primary btn-plus">
                                        <i className="fa fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row px-xl-5">
                    <div className="col">
                        <div className="nav nav-tabs justify-content-center border-secondary mb-4">
                            <a className="nav-item nav-link active" data-toggle="tab" href="#tab-pane-1">Descriere</a>
                            <a className="nav-item nav-link" data-toggle="tab" href="#tab-pane-2">Compatibilitate</a>
                        </div>
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="tab-pane-1">
                                {/* <h4 className="mb-3">Product Description</h4> */}
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item px-0">
                                        <b>Descriere</b>: {product.description}
                                    </li>
                                    <li className="list-group-item px-0">
                                        <b>Stare</b>: {product.condition}
                                    </li>
                                    <li className="list-group-item px-0">
                                        <b>Status</b>: {product.status}
                                    </li>
                                    <li className="list-group-item px-0">
                                        <b>Testat</b>: {product.tested ? "Da" : "Nu"}
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-pane fade" id="tab-pane-2">
                                {/* <h4 className="mb-3">Compatibility</h4> */}
                                <div className="row">
                                    <div className="col-md-12">
                                        <ul className="list-group list-group-flush">
                                            {compatibleModels.map(cm => (
                                            <li className="list-group-item px-0">
                                                {cm.name}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    );
}

export default Detail;