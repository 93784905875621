import React from 'react'
import Nav from './components/navigation';
import Detail from './pages/detail';
import Footer from './components/footer';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import UploadProduct from './pages/uploadproduct';
import EditProduct from './pages/editproduct';
import UploadCategory from './pages/uploadcategory';
import UploadBrand from './pages/uploadbrand';
import UploadModel from './pages/uploadmodel';
import Orders from './pages/orders';
import Order from './pages/order';
import Products from './pages/products';
// import 'bootstrap/dist/css/bootstrap.min.css';


import { AuthProvider } from './context/AuthContext'
import Login from './pages/login'

import { useAuth } from './context/AuthContext'

function App() {

  function RequireAuth({ children }) {
    
    const authed = useAuth();

    console.log("RequireAuth");
    console.log(authed);

    console.log(children);
  
    return authed.userIsAuthenticated() === true ? children : <Navigate to="/login" replace />;
  }

  return (
    <AuthProvider>
      <Router>
        <div>
          <Nav />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={
              <RequireAuth>
                <Orders />
              </RequireAuth>
              }>
            </Route>
            <Route path="/products" 
              element={
                <RequireAuth>
                  <Products/>
                </RequireAuth>
                }>
            </Route>
            <Route path="/uploadproduct/" element={
              <RequireAuth>
                <UploadProduct/>
              </RequireAuth>
              }>
            </Route>
            <Route path="/editproduct/:id" element={
              <RequireAuth>
                  <EditProduct/>
                </RequireAuth>}>
              </Route>
              <Route path="/uploadcategory" element={
                <RequireAuth>
                  <UploadCategory/>
                </RequireAuth>}>
              </Route>
              <Route path="/uploadbrand" element={
                <RequireAuth>
                  <UploadBrand/>
                </RequireAuth>}>
              </Route>
            <Route path="/uploadmodel" element={
              <RequireAuth>
                <UploadModel/>
              </RequireAuth>}>
            </Route>
            <Route path="/detail/:id" element={
              <RequireAuth>
                <Detail/>
              </RequireAuth>}>
            </Route>
            <Route path="/orders" element={
              <RequireAuth>
                <Orders/>
              </RequireAuth>}>
            </Route>
            <Route path="/order/:id" element={
              <RequireAuth>
                <Order/>
              </RequireAuth>}>
            </Route>
          </Routes>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
